import "./styles.css";
import { MicrobitWebBluetoothConnection } from "@microbit/microbit-connection";

import PartySocket from "partysocket";
import { people } from "./constants";

declare const PARTYKIT_HOST: string;

let myName: string | undefined = undefined;
let myTeam: Team | undefined = undefined;
const game = document.querySelector("#game")!;

const microbitConnection = new MicrobitWebBluetoothConnection();
(document.querySelector("#playagain") as HTMLButtonElement).style.display =
  "none";

(document.querySelector("button.start") as HTMLButtonElement).onclick =
  async () => {
    chooseUser();
  };

(document.querySelector("button.playagain") as HTMLButtonElement).onclick =
  async () => {
    playAgainClick();
  };

// A PartySocket is like a WebSocket, except it's a bit more magical.
// It handles reconnection logic, buffering messages while it's offline, and more.
const partySocket = new PartySocket({
  host: PARTYKIT_HOST,
  room: "my-new-room",
});

// You can even start sending messages before the connection is open!
partySocket.addEventListener("message", (event) => {
  console.log(`Received -> ${event.data}`);
  const json = JSON.parse(event.data);
  switch (json.type) {
    case "target": {
      handleTargetMessage(json);
      break;
    }
    case "gameover": {
      handleGameoverMessage(json);
      break;
    }
    case "impact": {
      handleImpactMessage(json);
      break;
    }
    case "team": {
      handleTeamMessage(json);
      break;
    }
    case "playagain": {
      playAgainUi();
    }
    default: {
      // Ignore unknown!
    }
  }
});

const handleGameoverMessage = ({
  scores,
}: {
  scores: Record<Team, number>;
}) => {
  const resultEl = document.querySelector("#result") as HTMLParagraphElement;
  if (!resultEl) {
    return;
  }
  if (scores.red > scores.blue) {
    resultEl.textContent = "Red won!";
    resultEl.style.color = "red";
  } else if (scores.blue > scores.red) {
    resultEl.textContent = "Blue won!";
    resultEl.style.color = "blue";
  } else {
    resultEl.textContent = "Tie!";
  }
  (document.querySelector("#playagain") as HTMLButtonElement).style.display =
    "block";
};

const handleTargetMessage = ({
  target,
  team,
}: {
  target: string;
  team: Team;
}) => {
  const imageContainer = document.querySelector(".target .image-container");
  if (imageContainer) {
    (imageContainer as HTMLElement).style.transform = "translateY(100%)";
  }
  setTimeout(() => {
    document.querySelector(".target")?.remove();
    document.querySelectorAll(".snowball").forEach((s) => s.remove());
    const person = people.find((p) => p.name === target);
    if (person) {
      const targetOuter = document.createElement("div");
      const targetInner = document.createElement("div");
      targetOuter.style.position = "absolute";
      // Somewhere near the floor!
      targetOuter.style.top = "60%";
      // Could also be server-side?
      const leftPercent = Math.random() * 100;
      const left = `${leftPercent}%`;
      targetOuter.style.left = left;
      // Tweak usual transform to keep image in bounds
      targetOuter.style.transform = `translateX(${leftPercent > 50 ? "-100%" : "50%"}) translateY(-50%)`;
      targetInner.style.position = "relative";
      const image = createPersonImage({ person, size: "300px" });
      // TODO: Color
      image.style.borderColor = team;
      image.style.boxSizing = "border-box";
      image.style.borderWidth = "5px";
      image.style.borderStyle = "solid";
      image.style.borderStyle = team === "blue" ? "dashed" : "solid";

      const overflowContainer = document.createElement("div");
      overflowContainer.style.width = "300px";
      overflowContainer.style.height = "300px";
      overflowContainer.style.overflow = "hidden";
      const imageContainer = document.createElement("div");
      imageContainer.className = "image-container";
      imageContainer.style.position = "relative";
      imageContainer.style.width = "300px";
      imageContainer.style.height = "300px";
      imageContainer.style.transform = "translateY(100%)";
      imageContainer.style.transition = "transform 500ms";
      imageContainer.appendChild(image);
      overflowContainer.appendChild(imageContainer);
      targetOuter.className = "target";
      targetInner.className = "targetInner";
      targetOuter.appendChild(targetInner);
      targetInner.appendChild(overflowContainer);
      game.appendChild(targetOuter);
      setTimeout(() => {
        imageContainer.style.transform = "translateY(0%)";
      }, 100);
    }
  }, 500);
};

const handleTeamMessage = (message: { team: Team | undefined }) => {
  myTeam = message.team;
  const person = people.find((p) => p.name === myName)!;
  const image = createPersonImage({ person, size: "80px" });
  image.style.position = "absolute";
  image.style.top = "10px";
  image.style.right = "10px";
  image.style.borderRadius = "50%";
  image.style.borderColor = myTeam as string;
  image.style.borderWidth = "5px";
  image.style.borderStyle = myTeam === "blue" ? "dashed" : "solid";
  game.appendChild(image);
  document.querySelector(".start")?.remove();
};

const handleImpactMessage = ({
  source,
  target,
  hit,
  scores,
}: {
  source: string;
  target: string;
  hit: boolean;
  scores: Record<Team, number>;
}) => {
  const snowballOuter = document.createElement("div");
  snowballOuter.className = "snowball";
  snowballOuter.style.position = "absolute";
  const snowballInner = document.createElement("div");
  snowballInner.style.position = "relative";
  snowballOuter.appendChild(snowballInner);
  snowballOuter.style.zIndex = "1";

  const snowball = document.createElement("img");
  snowball.src = "snowball.png";
  snowball.style.height = "120px";
  snowball.style.width = "120px";
  snowballInner.appendChild(snowball);
  snowballOuter.style.transform = "translateX(-50%) translateY(-50%)";

  document.querySelector("#red-score")!.textContent = scores.red.toString();
  document.querySelector("#blue-score")!.textContent = scores.blue.toString();

  // We could/should do this server side. Calculate a point then test for intersection?
  if (hit) {
    snowballOuter.style.top = "40%";
    snowballOuter.style.left = "50%";
  } else {
    snowballOuter.style[Math.random() > 0.5 ? "top" : "bottom"] =
      `${-(Math.random() * 100)}px`;
    snowballOuter.style[Math.random() > 0.5 ? "left" : "right"] =
      `${-(Math.random() * 100)}px`;
  }

  const person = createPersonImage({
    person: people.find((p) => p.name === source)!,
  });
  person.style.position = "absolute";
  person.style.top = "-7px";
  person.style.left = "-7px";
  person.style.bottom = "0";
  person.style.right = "0";
  person.style.opacity = "34%";
  person.style.borderRadius = "50%";
  person.style.padding = "20px";
  person.style.height = "70%";
  person.style.transform = "filter: saturate(150) grayscale(1) contrast(10)";

  snowballInner.appendChild(person);

  if (hit) {
    const imageContainer = document.querySelector(
      ".targetInner .image-container",
    );
    imageContainer?.appendChild(snowballOuter);
  } else {
    const targetInner = document.querySelector(".targetInner");
    targetInner?.appendChild(snowballOuter);
  }

  if (hit && target === myName) {
    // TODO: serialWrite vs writeUART (!)
    microbitConnection.writeUART(new TextEncoder().encode("snowball\n"));
  }
};

const chooseUser = () => {
  const dialog = document.querySelector("dialog")!;
  people.forEach((person) => {
    const button = document.createElement("button");
    button.onclick = async () => {
      myName = person.name;
      partySocket.send(
        JSON.stringify({
          type: "register",
          name: person.name,
        }),
      );
      dialog.close();
      microbitConnection.addEventListener("uartdata", (e) => {
        if (new TextDecoder().decode(e.value).trim() === "snowball") {
          console.log("Sending a snowball!");
          partySocket.send(
            JSON.stringify({ type: "snowball", source: myName }),
          );
        }
      });
      await microbitConnection.connect();
    };
    button.style.cursor = "pointer";
    const image = createPersonImage({ person });
    button.appendChild(image);
    dialog.appendChild(button);
  });
  dialog.showModal();
};

const playAgainClick = () => {
  partySocket.send(JSON.stringify({ type: "playagain" }));
  playAgainUi();
};

const playAgainUi = () => {
  (document.querySelector("#playagain") as HTMLButtonElement).style.display =
    "none";
  document.querySelector(".target")?.remove();
  document.querySelectorAll(".snowball").forEach((s) => s.remove());
  document.querySelector("#red-score")!.textContent = "0";
  document.querySelector("#blue-score")!.textContent = "0";
};

const createPersonImage = ({
  person,
  size,
}: {
  person: { name: string; url: string };
  size?: string;
}) => {
  const image = document.createElement("img");
  image.src = person.url;
  image.alt = person.name;
  if (size) {
    image.style.width = size;
    image.style.height = size;
  }
  return image;
};
